import { createRouter, createWebHistory } from 'vue-router';

export const constantRoutes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '智护打卡',
    },
    component: () => import('@/views/Home'),
  },
  // {
  //   path: '/newindex',
  //   name: 'newindex',
  //   meta: {
  //     title: '智护打卡',
  //   },
  //   component: () => import('@/views/Home/indexnew.vue'),
  // },
  {
    path: '/Center',
    name: 'Center',
    meta: {
      title: '智护打卡',
    },
    component: () => import('@/views/Home/Center.vue'),
  },

  {
    path: '/qingjia',
    name: 'qingjia',
    meta: {
      title: '智护打卡',
    },
    component: () => import('@/views/Home/qingjia.vue'),
  },

  {
    path: '/KeXueWeiYang',
    name: 'KeXueWeiYang',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/KeXueWeiYang.vue'),
  },
  {
    path: '/HuanNiaoBu',
    name: 'HuanNiaoBu',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/HuanNiaoBu.vue'),
  },

  {
    path: '/baobaomeirihuli',
    name: 'baobaomeirihuli',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/MeiRiHuLi.vue'),
  },
  {
    path: '/baobaoshengzhangzhibiao',
    name: 'baobaoshengzhangzhibiao',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/ShengZhangFaYu.vue'),
  },
  {
    path: '/baobaoshuimian',
    name: 'baobaoshuimian',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/ShuiMian.vue'),
  },
  {
    path: '/jinengpeixun',
    name: 'jinengpeixun',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/JiNengPeiXun.vue'),
  },
  {
    path: '/mamameirihuli',
    name: 'mamameirihuli',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/MaMaMeiRiHuLi.vue'),
  },
  {
    path: '/xinaiqi',
    name: 'xinaiqi',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/XiNaiQi.vue'),
  },
  {
    path: '/zhilikaifa',
    name: 'zhilikaifa',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/ZhiLiKaiFa.vue'),
  },
  {
    path: '/yichangqingkuang',
    name: 'yichangqingkuang',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/YiChangQingKuang.vue'),
  },
  {
    path: '/teshuyongyao',
    name: 'teshuyongyao',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/TeShuYongYao.vue'),
  },
  {
    path: '/yimiao',
    name: 'yimiao',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/YiMiao.vue'),
  },
  {
    path: '/exception',
    name: 'exception',
    meta: { title: '智护小馨' },
    component: () => import('@/views/Home/Exception.vue'),
  },
  {
    path: '/salary',
    name: 'salary',
    meta: { title: '智护打卡' },
    component: () => import('@/views/Home/salary.vue'),
  },
  {
    path: '/checkrecord',
    name: 'checkrecord',
    meta: { title: '智护打卡' },
    component: () => import('@/views/Home/checkrecord.vue'),
  },
  {
    path: '/mamaqingxu',
    name: 'mamaqingxu',
    meta: { title: '每日打卡' },
    component: () => import('@/views/Card/qingxu.vue'),
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;
