<template>
    <div style="width: 100%;">
        <van-cell :title="title" is-link :value="timesvalue" @click="showPopup" />
        <van-popup
            v-model:show="show"
            round
            position="bottom"
        >
            <van-picker-group
                :title="title"
                :tabs="newtabs"
                next-step-text="下一步"
                @confirm="onConfirm"
                @cancel="onCancel"
            >
                <template v-if="newtabs.includes('选择日期')">
                    <van-date-picker 
                        v-model="currentDate" 
                        :min-date="minDate" 
                        :max-date="maxDate" 
                    />
                </template>
                <template v-if="newtabs.includes('选择时间')">
                    <van-time-picker v-model="currentTime" />
                </template>
            </van-picker-group>
        </van-popup>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, onMounted } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    newtabs: {
        type: Array,
        required: true
    },
    code: {
        type: String,
        required: false
    },
    value: {
        type: String,
        required: false
    },
    limitDate: {
        type: String,
        required: false,
        default: 'no'
    }
});

const emit = defineEmits(['updateDateTime', 'change']);

const timesvalue = ref('请选择');
// 在组件挂载时判断传入的 value
onMounted(() => {
    console.log(props.value, 'props.value');
    if (props.value) {
        timesvalue.value = props.value;
    }
});
const show = ref(false);
const showPopup = () => {
    show.value = true;
};

// 获取当前日期和时间
const now = new Date();
const yesterday = new Date(now);
yesterday.setDate(now.getDate() - 1);
const today = new Date(now);
const minDate = ref();
const maxDate = ref();
// displayRecentTwoDays = 显示最近两天的时间
// hidePastDays = 不显示当天之前的时间
if (props.limitDate === 'displayRecentTwoDays') {
    minDate.value = yesterday;
    maxDate.value = today;
} else if (props.limitDate === 'hidePastDays') {
    minDate.value = today;
    maxDate.value = undefined; // 不限制最大日期
} else {
    minDate.value = undefined;
    maxDate.value = undefined;
}

const currentDate = ref([
    now.getFullYear().toString(),
    (now.getMonth() + 1).toString().padStart(2, '0'),
    now.getDate().toString().padStart(2, '0')
]);
const currentTime = ref([
    now.getHours().toString().padStart(2, '0'),
    now.getMinutes().toString().padStart(2, '0')
]);

// 监听 currentDate 和 currentTime 的变化
watch(currentDate, (newVal) => {
    emit('change', { type: 'date', value: newVal });
});
watch(currentTime, (newVal) => {
    emit('change', { type: 'time', value: newVal });
});

// 确认按钮
const onConfirm = () => {
    let dateTime = '';
    if (props.newtabs.includes('选择日期')) {
        dateTime += currentDate.value.join('-');
    }
    if (props.newtabs.includes('选择时间')) {
        dateTime += (dateTime ? ' ' : '') + currentTime.value.join(':');
    }
    if (dateTime) {
        console.log(dateTime);
        timesvalue.value = dateTime;
        emit('updateDateTime', { code: props.code, dateTime });
    }
    show.value = false;
};

// 取消按钮
const onCancel = () => {
    show.value = false;
    currentDate.value = [
        now.getFullYear().toString(),
        (now.getMonth() + 1).toString().padStart(2, '0'),
        now.getDate().toString().padStart(2, '0')
    ]; // 重置为默认值
    currentTime.value = [
        now.getHours().toString().padStart(2, '0'),
        now.getMinutes().toString().padStart(2, '0')
    ]; // 重置为默认值
    console.log(currentDate.value, currentTime.value);
};
</script>

<style>
.van-cell{
    padding: 10px 14px 10px 0px;
}
.van-cell--clickable{
    border: 1px solid #CBAAFF;
    border-radius: 3px;
    padding: 5px 10px;
}
</style>