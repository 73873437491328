<template>
  <router-view></router-view>
</template>

<script setup>
</script>

<style>
#app{
  background: #f7f8fa;
}
</style>
